import React, { FC } from 'react'
import cx from 'clsx'
import BlockContent from '@/components/blocks/block-content'
import { Blocks, FreeformFontSize, FreeformTextAlign } from '@/types/site.interface'

export interface FreeFormProps {
  className?: string
  _type?: 'freeform'
  _key?: string
  content: Blocks
  textAlign?: FreeformTextAlign
  fontSize?: FreeformFontSize
}

export const Freeform: FC<FreeFormProps> = ({ className, textAlign, fontSize, content }) => {
  return (
    <BlockContent
      className={cx(
        textAlign,
        fontSize,
        'prose-h2:text-center prose-h2:text-3xl md:prose-h2:text-4xl prose-a:text-primary',
        'my-10 prose prose-lg prose-indigo mx-auto mt-6',
        className,
      )}
      blocks={content}
    />
  )
}

export default Freeform
